import Pumpkin from './components/singleAnimations/Pumpkin';
import { useEffect, useState } from 'react';

const pumpkinNumbers = [0, 48, 172, 189, 204, 608, 205, 137, 314, 463, 502];
// const pumpkinNumbers = [0, 48];

function PumpkinWalk({onSingEnd, onSingStart}) {
  // const [animation, setAnimation] = useState('walk');
  const [animationIndex, setAnimationIndex] = useState(pumpkinNumbers.length);

  useEffect(() => {
    if (animationIndex > 0) {
      setTimeout(() => {
        setAnimationIndex(animationIndex - 1);
      }, 500)
    }
  }, [animationIndex])

  const switchAnimation = () => {
    console.log('end');
  }

  return (
    <div className="PumpkinWalk">
      {pumpkinNumbers.map((number, index) => (
        <Pumpkin
          onSingEnd={onSingEnd}
          onSingStart={onSingStart}
          number={number}
          animate={index >= animationIndex}
          index={index}
          key={index}
          onAnimationEnd={switchAnimation}
        />
      ))}
    </div>
  );
}

export default PumpkinWalk;
