import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  top: 3%;
  right: 3%;
  background: #742106;
  border: 5px solid black;
  border-radius: 30px;
  padding: 10px;
  padding-left: 40px;
  padding-right: 40px;
  box-shadow: 0 0 0 10px orange;
  max-width: 25%;
`

const Header = styled.h1`
  color: Red;
  font-size: 36px;
`

const Paragraph = styled.p`
  color: orange;
  font-size: 24px;
`

const Info = () => {
  return (
    <Container>
      <Header>We are PUMPKIEZ</Header>
      <Paragraph>1. We just want to ROCK</Paragraph>
      <Paragraph>2. We just want to LOVE</Paragraph>
      <Paragraph>3. We just want... BLOOD!!!!!!</Paragraph>
    </Container>
  );
}

export default Info;