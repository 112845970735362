import './App.css';
import PumpkinWalk from './PumpkinWalk';
import Info from './Info';
import { useEffect, useRef, useState } from 'react';

import styled from 'styled-components'

const ComingSoon = styled.p`
  left: 40%;
  position: absolute;
  color: orange;
  font-size: 36px;
`

const SoundOn = styled.div`
  position: absolute;
  top: 3%;
  left: 3%;
  z-index: 1;
  color: white;
  font-size: 24px;
  font-weight: bold;
`

function App() {
  const [background, setBackground] = useState('haunted.png');
  const [started, setStarted] = useState(false);
  const [gate, setGate] = useState('closed');
  const [displaySoonText, setDisplaySoonText] = useState(false);
  
  const songRef = useRef();
  const thunderRef = useRef();

  const singStart = () => {
    setBackground('stage.jpeg');
    songRef.current.pause();
  }

  const singEnd = () => {
    setDisplaySoonText(true);
  }

  const closedGateClick = () => {
      thunderRef.current.play();
      setGate('open');
  }

  useEffect(() => {
    if (gate === 'open') {
      setTimeout(() => {
        setGate('none');
        !started && songRef.current.play();
        setStarted(true);
      }, 1000)
    }
  }, [gate, started])
  
  return (
    <div style={{backgroundImage: `url('backgrounds/${background}')`, backgroundSize: "100% 100%", width: "100vw", height: "100vh"}}>
      {gate === 'closed' ? (
        <img onClick={closedGateClick} src="backgrounds/gate-closed.png" alt="haunted entry gate" style={{width: "100vw"}}/>
      ) : null }
      {gate === 'open' ? (
        <img src="backgrounds/gate-open.png" alt="haunted entry gate" style={{width: "100vw"}}/>
      ) : null }
      {gate === 'none' ? <PumpkinWalk onSingEnd={singEnd} onSingStart={singStart}/> : null };
      <SoundOn>🔊 SOUND ON PLEASE, DESKTOP ONLY</SoundOn>
      {displaySoonText ? <Info /> : null}
      {displaySoonText ? <ComingSoon>COMING SOON</ComingSoon> : null}
      <audio autoplay ref={songRef} style={{display: 'none'}}>
        <source src="audio/blood-tonight.mp3" type="audio/mpeg" />
      </audio>
      <audio autoplay ref={thunderRef} style={{display: 'none'}}>
        <source src="audio/thunder.mp3" type="audio/mpeg" />
      </audio>
    </div>
  );
}

export default App;
